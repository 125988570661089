var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AutheticatedFrame',[_c('v-app',[_c('v-app-bar',{attrs:{"height":"48","color":"rgb(82, 82, 82)","fixed":"","clipped-left":"","app":"","elevation":1}},[_c('img',{staticStyle:{"filter":"brightness(180%)"},attrs:{"src":require("@/assets/title.png"),"width":"120"}}),_c('div',{staticClass:"white--text mt-1 ml-2 text-truncate"},[_vm._v("接続番号："+_vm._s(_vm.roomInfoService.roomInfo? _vm.roomInfoService.roomInfo.connectionNumber: ""))]),_c('v-spacer'),_c('div',{staticClass:"white--text mt-1 ml-2 mr-2"},[_vm._v(" "+_vm._s(_vm.roomInfoService.roomInfo ? _vm.roomInfoService.roomInfo.name : "")+" ")]),_c('v-btn',{attrs:{"text":"","color":"white","fab":"","small":""},on:{"click":_vm.reload}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-main',[_c('div',{staticClass:"fixed",staticStyle:{"top":"48px","bottom":"0","right":"0","left":"0","z-index":"999999","transition":"all 0.4s"},style:({
                            right:_vm.isRightPanelOpend
                                ? _vm.rightPanelWidth + 'px'
                                : '0px'
                        })},[_c('div',{staticClass:"strech"},[(_vm.screenMode === _vm.screenModeType.ScreenShare)?_c('v-fade-transition',[_c('ScreenShare')],1):(
                            _vm.screenMode === _vm.screenModeType.DocumentShare
                        )?_c('v-fade-transition',[_c('DocumentShare')],1):_vm._e(),_c('div',{staticStyle:{"width":"48px","position":"absolute","right":"0","top":"0px"}},[_c('v-badge',{attrs:{"color":"primary","value":_vm.unreadChatMessageCount,"inline":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.unreadChatMessageCount))])]},proxy:true}])},[_c('v-btn',{staticClass:"mt-2 white--text",attrs:{"fab":"","color":"#21212187","small":""},on:{"click":function($event){_vm.isRightPanelOpend = !_vm.isRightPanelOpend;
                                    _vm.unreadChatMessageCount = 0;}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.isRightPanelOpend ? "mdi-chevron-right" : "mdi-chevron-left")+" ")])],1)],1),_c('RightCommandBox',{staticStyle:{"z-index":"999"},attrs:{"isShowVideoChatModeButton":false},on:{"showProfile":_vm.showProfile},model:{value:(_vm.screenMode),callback:function ($$v) {_vm.screenMode=$$v},expression:"screenMode"}})],1),_c('v-container',{staticClass:"absolute pa-1",staticStyle:{"bottom":"0","right":"0","left":"0","z-index":"102"}},[_c('v-row',{staticStyle:{"height":"64px"},attrs:{"justify":"center","align":"center"}},[_c('v-btn',{staticClass:"mr-3 ml-6",staticStyle:{"color":"white","transition":"margin 0.4s"},attrs:{"color":"error","fab":""},on:{"click":_vm.hungupClicked}},[_c('v-icon',[_vm._v("mdi-phone-hangup")])],1),_c('v-btn',{staticClass:"mr-3",staticStyle:{"color":"white"},attrs:{"color":"#21212187","fab":""},on:{"click":_vm.switchFullScreenClicked}},[_c('v-icon',[_vm._v("mdi-overscan")])],1)],1)],1)],1)])]),_c('v-navigation-drawer',{staticClass:"drawer",attrs:{"hide-overlay":"","stateless":"","app":"","right":"","height":"100%","width":_vm.rightPanelWidth},model:{value:(_vm.isRightPanelOpend),callback:function ($$v) {_vm.isRightPanelOpend=$$v},expression:"isRightPanelOpend"}},[_c('div',{staticClass:"strech",staticStyle:{"display":"grid","grid-template-columns":"5px 1fr"}},[_c('div',{staticClass:"strech handle",on:{"mousedown":_vm.beginResizeRightPanel}}),_c('v-tabs',{staticClass:"strech",attrs:{"grow":"","color":"deep-purple"}},[_c('v-tab',[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"24","color":"deep-purple"}},[_vm._v("mdi-chat-outline")]),_vm._v("チャット ")],1),_c('v-tab-item',{staticStyle:{"height":"calc(100vh - 48px)"}},[_c('ChatView',{staticClass:"strech"})],1)],1)],1)]),(_vm.webRtcService.videoChatConnection)?_c('div',_vm._l((_vm.webRtcService.videoChatConnection
                    .remoteStreams),function(video,key){return _c('audio',{key:key,ref:"audioElement",refInFor:true,attrs:{"autoplay":""},domProps:{"srcObject":video},on:{"loadeddata":_vm.onSelectedOutputDeviceIdChanged}})}),0):_vm._e(),_c('div',{staticStyle:{"z-index":"9999999"}},[(_vm.isLoading)?_c('div',{staticClass:"fixed-strech",staticStyle:{"background":"#000000c4","z-index":"10000"}},[_c('v-progress-circular',{staticClass:"absolute-center",attrs:{"size":200,"color":"secondary","indeterminate":""}})],1):_vm._e(),_c('ConfirmLeaveRoomDialog',{ref:"confirmLeaveRoomDialog"}),_c('ConnectionSettingDialog',{ref:"connectionSettingDialog"}),_c('ProfileDisplayDialog',{ref:"profileDisplayDialog",on:{"send":_vm.onSend}}),_c('ProfileSelectDialog',{ref:"profileSelectDialog",on:{"preview":function($event){return _vm.onPreviewProfile($event[0], $event[1])},"profileSelected":_vm.onSendProfileToRoom}}),_c('v-snackbar',{attrs:{"top":"","color":_vm.notifyColor,"timeout":_vm.notifyTimeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","text":"","fab":""},on:{"click":function($event){_vm.isShowNotify = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.isShowNotify),callback:function ($$v) {_vm.isShowNotify=$$v},expression:"isShowNotify"}},[_vm._v(" "+_vm._s(_vm.notifyMessage)+" ")]),_c('InputDialog',{ref:"inputDialog"}),_c('ConfirmDialog',{ref:"confirmDialog"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }